import { postToSalesforce } from 'services/salesforceService'

const GeneralSupportConfig = {
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'Name',
          name: 'name',
          required: true
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email Address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'phone',
        params: {
          label: 'Phone Number',
          name: 'phone',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Name of location or venue',
          name: '00N3100000H6DTo',
          required: true
        }
      },
      {
        type: 'zip',
        params: {
          label: 'Zip/Postal Code',
          name: '00N3100000H6DTt',
          required: true
        }
      },
      {
        type: 'state',
        params: {
          label: 'State',
          name: 'State',
          required: true
        }
      },
      {
        type: 'list',
        params: {
          label: 'Web Inquiry Type',
          name: '00N6e00000HJtiI',
          required: true,
          options: [
            { value: 'Prospective Clients', label: 'Prospective Clients' },
            { value: 'Existing Accounts', label: 'Existing Accounts' },
            { value: 'Invoices and Payments', label: 'Invoices and Payments' },
            { value: 'Supplier and W-9 Requests', label: 'Supplier and W-9 Requests' },
            { value: 'Career and Job Searches', label: 'Career and Job Searches' },
            { value: 'Employee Services', label: 'Employee Services' },
            { value: 'Payroll and W-2s', label: 'Payroll and W-2s' },
            { value: 'Media Relations', label: 'Media Relations' },
            { value: 'Other', label: 'Other' }
          ]
        }
      },
      {
        type: 'text',
        params: {
          label: 'Customer, Invoice, or Order #',
          name: '00N6e00000HJtiM',
          required: false
        }
      },
      {
        type: 'checkbox-group',
        params: {
          label: 'Type of Services Received',
          name: '00N6e00000HJtiH',
          options: [
            { value: 'Collegiate Hospitality', label: 'Collegiate Hospitality' },
            { value: 'Concessions', label: 'Concessions' },
            { value: 'Facility Management', label: 'Facility Management' },
            { value: 'Sports and Entertainment', label: 'Sports and Entertainment' },
            { value: 'Guest Reservation', label: 'Guest Reservation' },
            { value: 'Healthcare+', label: 'Healthcare+' },
            { value: 'Refreshments', label: 'Refreshments' },
            { value: 'Vending', label: 'Vending' },
            { value: 'Student Nutrition', label: 'Student Nutrition' },
            { value: 'Workplace Experience Group', label: 'Workplace Experience Group' },
            { value: 'Uniforms', label: 'Uniforms' },
            { value: 'Others', label: 'Others' }
          ],
          required: false
        }
      },
      {
        type: 'comments',
        params: {
          label: 'Tell us about your needs',
          name: 'description',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Record Type',
          name: 'recordType',
          value: '01231000000y2Tn',
          hidden: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default GeneralSupportConfig
