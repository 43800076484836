import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const sEHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

sEHubConfig.service = postToSalesforce
sEHubConfig?.form?.fields?.splice(3, 0, {
  type: 'checkbox-group',
  params: {
    label: 'Area of Interest',
    name: '00Ni000000GKKjV',
    options: [
      { value: 'Convention Centers', label: 'Convention Centers' },
      { value: 'Food Services', label: 'Food Services' },
      { value: 'Stadiums / Arenas / Amphitheaters', label: 'Stadiums / Arenas / Amphitheaters' }
    ],
    required: true
  }
})

sEHubConfig?.form?.fields?.splice(7, 0, {
  type: 'state',
  params: {
    label: 'State',
    name: 'State',
    required: true
  }
})

const sEHubObj = [{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Stadiums, Arenas & Amphitheaters',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Line of Business',
    name: 'Line_of_Business__c',
    value: 'Sports & Entertainment',
    hidden: true
  }
}];

sEHubConfig?.form?.fields?.push(...sEHubObj);


export default sEHubConfig
