import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const healthcareHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

healthcareHubConfig.service = postToSalesforce
healthcareHubConfig?.form?.fields?.splice(6, 0, {
  type: 'state',
  params: {
    label: 'State',
    name: 'State',
    required: true
  }
})
const healthcareHubObj = [{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Hospitals & Healthcare Facilities',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Line of Business',
    name: 'Line_of_Business__c',
    value: 'Healthcare Hospitality',
    hidden: true
  }
}];

healthcareHubConfig?.form?.fields?.push(...healthcareHubObj);

export default healthcareHubConfig
