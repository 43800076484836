import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const facilitiesHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

facilitiesHubConfig.service = postToSalesforce
facilitiesHubConfig?.form?.fields?.push(
  {
    type: 'text',
    params: {
      label: 'Industry',
      name: 'industry',
      value: 'Facilities Services',
      hidden: true
    }
  }
)
facilitiesHubConfig?.form?.fields?.push({
  type: 'checkbox-group',
  params: {
    label: 'Services Interested in',
    name: '00Ni000000GKKjV',
    options: [
      { value: 'Mats / Soap / Towels / Uniforms / First Aid', label: 'Mats / Soap / Towels / Uniforms / First Aid' },
      { value: 'Custodial / Janitorial / Sanitation Services', label: 'Custodial / Janitorial / Sanitation Services' },
      { value: 'Operations & Maintenance Services', label: 'Operations & Maintenance Services' },
      { value: 'Integrated Facilities Management', label: 'Integrated Facilities Management' }
    ],
    required: true
  }
})
facilitiesHubConfig?.form?.fields?.push(
  {
    type: 'comments',
    params: {
      label: 'Tell us about your needs',
      name: 'Tell_us_about_your_needs__c',
      required: true
    }
  }
)
facilitiesHubConfig?.form?.fields?.push(
  {
    type: 'text',
    params: {
      label: 'Lead Source',
      name: 'lead_source',
      value: 'Aramark.com Contact Form',
      hidden: true
    }
  }
)
facilitiesHubConfig?.form?.fields?.push(
  {
    type: 'text',
    params: {
      label: 'Line of Business',
      name: 'Line_of_Business__c',
      value: 'Facilities',
      hidden: true
    }
  }
)

export default facilitiesHubConfig
