import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const seniorLifeWebLeads = JSON.parse(JSON.stringify(LobHubReachOutConfig))

seniorLifeWebLeads.service = postToSalesforce
seniorLifeWebLeads?.form?.fields?.splice(3, 0, {
  type: 'checkbox-group',
  params: {
    label: 'Area of Interest',
    name: '00Ni000000GKKjV',
    options: [
      { value: 'Dining', label: 'Dining' },
      { value: 'Housekeeping & Infection Prevention', label: 'Housekeeping & Infection Prevention' },
      { value: 'Amenities', label: 'Amenities' },
      { value: 'Programs', label: 'Programs' },
      { value: 'Nutrition & Wellness', label: 'Nutrition & Wellness' },
      { value: 'Technology', label: 'Technology' }
    ],
    required: true
  }
})

const sLifeLeads = [
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Senior Living',
    hidden: true
  }
}];

seniorLifeWebLeads?.form?.fields?.push(...sLifeLeads);

export default seniorLifeWebLeads;