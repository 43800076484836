import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const destinationsHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

destinationsHubConfig.service = postToSalesforce
 

const destinationsHubObj = [{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Destinations & Cultural',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Line of Business',
    name: 'Line_of_Business__c',
    value: 'Leisure',
    hidden: true
  }
}];

destinationsHubConfig?.form?.fields?.push(...destinationsHubObj);

export default destinationsHubConfig
