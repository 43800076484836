import { postToSalesforce } from 'services/salesforceService'

const InternationalContactus = {
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'first_name',
          required: true
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'last_name',
          required: true
        }
      },
      {
        type: 'rut',
        params: {
          label: 'RUT',
          name: 'rut',
          required: false,
          hidden: true
        }
      },
      {
        type: 'company',
        params: {
          label: 'Organization',
          name: 'company',
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email Address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'telPhone',
        params: {
          label: 'Phone Number',
          name: 'phone',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Zip/Postal Code',
          name: 'zipcode'
        }
      },
      {
        type: 'state',
        params: {
          label: 'State',
          name: 'State',
          required: true
        }
      },
      {
        type: 'comments',
        params: {
          label: 'Comment / Questions',
          name: 'description',
          required: true
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'Yes, I want to receive communications by e-mail about our services.',
          name: 'gdpr',
          value: true
        }
      },
      {
        type: 'checkbox',
        params: {
          label: '*I have read and accept the privacy policy.',
          name: 'privacyPolicy',
          value: true,
          required: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default InternationalContactus
