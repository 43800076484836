import { postToSalesforce } from 'services/salesforceService'

const BusinessInquiryConfig = {
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'first_name',
          required: true
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'last_name',
          required: true
        }
      },
      {
        type: 'company',
        params: {
          label: 'Organization',
          name: 'company',
        }
      },
      {
        type: 'industry',
        params: {
          label: 'Industry',
          name: 'industry',
          required: true,
          options: [
            { value: 'Colleges & Universities', label: 'Colleges & Universities' },
            { value: 'K12 Schools & Districts', label: 'K12 Schools & Districts' },
            { value: 'Workplaces & Manufacturing Facilities', label: 'Workplaces & Manufacturing Facilities' },
            { value: 'Conference Centers', label: 'Conference Centers' },
            { value: 'Stadiums, Arenas, & Amphitheaters', label: 'Stadiums, Arenas & Amphitheaters' },
            { value: 'Convention Centers', label: 'Convention Centers' },
            { value: 'Hospitals & Healthcare Facilities', label: 'Hospitals & Healthcare Facilities' },
            { value: 'Destinations & Cultural', label: 'Destinations & Cultural Attractions' },
            { value: 'Correctional Facilities', label: 'Correctional Facilities' }
          ]
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email Address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'phone',
        params: {
          label: 'Phone Number',
          name: 'phone'
        }
      },
      {
        type: 'zip',
        params: {
          label: 'Zip / Postal Code',
          name: 'zip'
        }
      },
      {
        type: 'state',
        params: {
          label: 'State',
          name: 'State',
          required: true
        }
      },
      {
        type: 'comments',
        params: {
          label: 'Comment / Questions',
          name: 'description',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Record Type',
          name: 'recordType',
          value: '012i0000000xgiK',
          hidden: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Lead Source',
          name: 'lead_source',
          value: 'Aramark.com Contact Form',
          hidden: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default BusinessInquiryConfig
