import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const correctionsHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

correctionsHubConfig.service = postToSalesforce

const correctionsHubObj = [{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Correctional Facilities',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Line of Business',
    name: 'Line_of_Business__c',
    value: 'Corrections',
    hidden: true
  }
},
{
  type: 'state',
  params: {
    label: 'State',
    name: 'state',
    required: true
  }
}];

correctionsHubConfig?.form?.fields?.push(...correctionsHubObj);

export default correctionsHubConfig
