
const MyPayFormConfig = {
  captcha: true,
  service: '',
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'empId',
        params: {
          label: 'Former Aramark Employee ID',
          name: 'employeeId',
          required: true,
          fieldDesc:'Enter your former 9-digit Aramark Employee ID'
        }
      },
      {
        type: 'email',
        params: {
          label: 'Current Email Address',
          name: 'emailAddress',
          required: true
        }
      },
      {
        type: 'datePicket',
        params: {
          label: 'Date of Birth',
          name: 'dateOfBirth',
          required: true
        }
      },
      { 
        type: 'radio-group',
        params: {
          label: 'Select your Country',
          name: 'country',
          options: [
            {value: 'US', label: 'US'},
            {value: 'Canada', label: 'Canada'},
          ],
          value:'US',
          required: true
        }
      },
      {
        type: 'usSSN',
        params: {
          label: 'Social Security Number',
          name: 'socialSecurityNumber',
          required: true,
          fieldDesc:'Please enter the last 4 digits of your SSN',
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'My Pay Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default MyPayFormConfig;
