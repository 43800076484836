import { postToSalesforce } from 'services/salesforceService'

const InternationalBusinessInquiry = {
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'first_name',
          required: true
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'last_name',
          required: true
        }
      },
      {
        type: 'company',
        params: {
          label: 'Organization',
          name: 'company',
        }
      },
      {
        type: 'industry',
        params: {
          label: 'Industry',
          name: 'industry',
          required: true,
          options: [
            { value: 'Business Workplace', label: 'Business Workplace' },
            { value: 'Manufacturing & Industry', label: 'Manufacturing & Industry' },
            { value: 'Colleges & Universities', label: 'Colleges & Universities' },
            { value: 'Health & Care', label: 'Health & Care' },
            { value: 'Defence', label: 'Defence' },
            { value: 'Retail', label: 'Retail' },
            { value: 'Sports & Entertainment', label: 'Sports & Entertainment' },
            { value: 'Destinations & Cultural Attractions', label: 'Destinations & Cultural Attractions' },
            { value: 'Extractive Industries', label: 'Extractive Industries' },
            { value: 'Facilities Management', label: 'Facilities Management' },
            { value: 'Senior Living', label: 'Senior Living' }
          ]
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email Address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'telPhone',
        params: {
          label: 'Phone Number',
          name: 'phone',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Zip/Postal Code',
          name: 'zipcode'
        }
      },
      {
        type: 'state',
        params: {
          label: 'State',
          name: 'State',
          required: true
        }
      },
      {
        type: 'comments',
        params: {
          label: 'Comment / Questions',
          name: 'description',
          required: true
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'Yes, I want to receive communications by e-mail about our services.',
          name: 'gdpr',
          value: true
        }
      },
      {
        type: 'checkbox',
        params: {
          label: '*I have read and accept the privacy policy.',
          name: 'privacyPolicy',
          value: true,
          required: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default InternationalBusinessInquiry
