const LobHubReachOutConfig = {
  captcha: true,
  form : {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'first_name',
          required: true
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'last_name',
          required: true
        }
      },
      {
        type: 'company',
        params: {
          label: 'Subject',
          name: 'company',
          required: true,
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'phone',
        params: {
          label: 'Phone Number',
          name: 'phone'
        }
      },
      {
        type: 'zip',
        params: {
          label: 'Zip / Postal Code',
          name: 'zip'
        }
      },
      {
        type: 'text',
        params: {
          label: 'Record Type',
          name: 'recordType',
          value: '012i0000000xgiK',
          hidden: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default LobHubReachOutConfig
