import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const k12HubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))


k12HubConfig.service = postToSalesforce
k12HubConfig?.form?.fields?.splice(3, 0, {
  type: 'checkbox-group',
  params: {
    label: 'Service Interested in',
    name: '00Ni000000GKKjV',
    options: [
      { value: 'Food Services', label: 'Food Services' },
      { value: 'Multiple Services', label: 'Multiple Services' }
    ],
    required: true
  }
})
k12HubConfig?.form?.fields?.splice(7, 0, {
  type: 'state',
  params: {
    label: 'State',
    name: 'State',
    required: true
  }
})

const k12HubObj = [
  {
    type: 'comments',
    params: {
      label: 'Tell us about your needs',
      name: 'Tell_us_about_your_needs__c',
      required: false
    }
  },
  {
    type: 'text',
    params: {
      label: 'Industry',
      name: 'industry',
      value: 'K12 Schools & Districts',
      hidden: true
    }
  },
  {
    type: 'text',
    params: {
      label: 'Lead Source',
      name: 'lead_source',
      value: 'Aramark.com Contact Form',
      hidden: true
    }
  },
  {
    type: 'text',
    params: {
      label: 'Line of Business',
      name: 'Line_of_Business__c',
      value: 'K-12',
      hidden: true
    }
  }];

k12HubConfig?.form?.fields?.push(...k12HubObj);

export default k12HubConfig
