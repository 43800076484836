import { postToSalesforce } from 'services/salesforceService'

const WXGFormConfig = {
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'first_name',
          required: true
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'last_name',
          required: true
        }
      },
      {
        type: 'email',
        params: {
          label: 'Email Address',
          name: 'email',
          required: true
        }
      },
      {
        type: 'phone',
        params: {
          label: 'Phone Number',
          name: 'phone',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Company Name',
          name: 'company',
          required: true
        }
      },
      {
        type: 'empNum',
        params: {
          label: 'Number of Employees',
          name: 'Employee_Count__c',
          required: true
        }
      },
      {
        type: 'zip',
        params: {
          label: 'Zip/Postal Code',
          name: 'zip',
          required: true
        }
      },
      {
        type: 'state',
        params: {
          label: 'State',
          name: 'state',
          required: true
        }
      },
      {
        type: 'checkbox-group',
        params: {
          label: 'Services Interested In',
          name: 'Service_Interests__c',
          options: [
            {value: 'Food Services', label: 'Food Services'},
            {value: 'Refreshments', label: 'Refreshments Services'},
            {value: 'Uniforms', label: 'Uniform Services'},
            {value: 'Facilities Services', label: 'Facilities Management'},
          ],
          required: true
        }
      },
      {
        type: 'comments',
        params: {
          label: 'Tell us about your needs',
          name: 'description',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Industry',
          name: 'industry',
          value: null,
          hidden: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Recordtype',
          name: 'recordType',
          value: '012i0000000xgiK',
          hidden: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Lead Source',
          name: 'lead_source',
          value: 'Aramark.com Contact Form',
          hidden: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Line of Business',
          name: 'Line_of_Business__c',
          value: 'Business Dining',
          hidden: true
        }
      }
    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default WXGFormConfig;
