import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const bIHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

bIHubConfig.service = postToSalesforce
bIHubConfig.form.fields.splice(3, 0, {
  type: 'checkbox-group',
  params: {
    label: 'Service Interested in',
    name: '00Ni000000GKKjV',
    options: [
      {value: 'Food Services', label: 'Food Services'},
      {value: 'Multiple Services', label: 'Multiple Services'},
      {value: 'Refreshments', label: 'Refreshments'}
    ],
    required: true
  }
})
bIHubConfig?.form?.fields?.push(
  {
    type: 'text',
    params: {
      label: 'Industry',
      name: 'industry',
      value: 'Workplaces & Manufacturing Facilities',
      hidden: true
    }
  }
)

export default bIHubConfig
