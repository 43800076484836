
const DoNotShareConfig = {
  captcha: true,
  service: '',
  form: {
    layout: 'list',
    theme: 'light',
    fields: [
      {
        type: 'TextView',
        params: {
          label: '1. Complete the following information of the person whose information is the subject of this request:',
        }
      },
      {
        type: 'lastName',
        params: {
          label: 'Last Name',
          name: 'lastName',
          required: true
        }
      },
      {
        type: 'firstName',
        params: {
          label: 'First Name',
          name: 'firstName',
          required: true
        }
      },
      {
        type: 'phone',
        params: {
          label: 'Phone Number',
          name: 'phoneNumber',
          required: true
        }
      },
      {
        type: 'email',
        params: {
          label: 'Current Email Address',
          name: 'emailAddress',
          required: true
        }
      },
      {
        type: 'state',
        params: {
          label: 'State of Residency',
          name: 'state',
          required: true
        }
      },
      {
        type: 'text',
        params: {
          label: 'Where have we served you',
          name: 'whereWeServedYou',
          required: false
        }
      },
      {
        type: 'TextView',
        params: {
          label: '2. Request Type [check all that apply]',
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'I request access to specific pieces of personal information you have about me (to better understand your request, please describe in text box below)',
          name: 'accesstoPIPieces',
          value: true
        }
      },
      {
        type: 'comments',
        params: {
          label: '',
          name: 'accessToPIPiecesComments',
          required: false
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'I request access to categories of personal information that you have collected about me (to better understand your request, please describe in text box below)',
          name: 'accesstoPICategories',
          value: true
        }
      },
      {
        type: 'comments',
        params: {
          label: '',
          name: 'accesstoPICategoriesComments',
          required: false
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'Delete specific pieces of my information (describe in text box below)',
          name: 'deletePIPieces',
          value: true
        }
      },
      {
        type: 'comments',
        params: {
          label: '',
          name: 'deletePIPiecesComments',
          required: false
        }
      },
      {
        type: 'TextView',
        params: {
          label: '3. Additional Details',
        }
      },
      {
        type: 'checkbox',
        params: {
          label: 'Please provide additional detail in the text box below.',
          name: 'optOutSalesOfPI',
          value: true
        }
      },
      {
        type: 'comments',
        params: {
          label: '',
          name: 'additionalDetails',
          required: false
        }
      },

    ],
    buttons: [
      {
        type: 'submit',
        params: {
          label: 'Submit',
          theme: {
            style: 'primary',
            type: 'button'
          }
        }
      }
    ]
  }
}

export default DoNotShareConfig;
