import { postToSalesforce } from 'services/salesforceService'
import LobHubReachOutConfig from './lobHubReachOutConfig'

const immerseSEHubConfig = JSON.parse(JSON.stringify(LobHubReachOutConfig))

immerseSEHubConfig.service = postToSalesforce

immerseSEHubConfig?.form?.fields?.splice(6, 0, {
  type: 'state',
  params: {
    label: 'State',
    name: 'State',
    required: true
  }
})

const sEHubObj = [{
  type: 'text',
  params: {
    label: 'Industry',
    name: 'industry',
    value: 'Stadiums, Arenas & Amphitheaters',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Lead Source',
    name: 'lead_source',
    value: 'Aramark.com Contact Form - IMMERSE',
    hidden: true
  }
},
{
  type: 'text',
  params: {
    label: 'Line of Business',
    name: 'Line_of_Business__c',
    value: 'Sports & Entertainment',
    hidden: true
  }
}];

immerseSEHubConfig?.form?.fields?.push(...sEHubObj);


export default immerseSEHubConfig
